.shipments-table {
  @media (max-width: 900px) {
    display: none;
  }
}

.shipments {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.4rem;

  display: none;

  @media (max-width: 900px) {
    display: grid;
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
}

.shipment {
  padding: 0.8rem;
  border-radius: 1.2rem;
  background-color: #fff;

  &__header {
    background-color: #f1f1f1;
    border-radius: 0.8rem;
    padding: 1.2rem;

    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__right {
    display: flex;
    align-items: center;
    gap: 1.2rem;
  }

  &__body {
    padding: 1.6rem;
  }
}
