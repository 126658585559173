$text-color: #555;

// $bg-primary: #fa5252;
$bg-primary: #8c0501;
// $bg-primary-faint: #c84242;
$bg-primary-faint: #700401;
$bg-primary-fainter: #ffeeee;
$text-primary: #1d1235;

$text-secondary: #7e8ca0;

$text-warning: #f57d7d;

$color-white: #fff;

$color-danger: #ff6b6b;
$color-warning: #f08c00;
$color-success: #51cf66;
$color-info: #0f5ffc;
