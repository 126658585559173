.invoice-header {
  max-width: 90rem;
  margin: auto;

  text-align: center;
  margin-bottom: 2.4rem;
}

.invoice {
  max-width: 90rem;
  margin: auto;

  padding: 2.4rem;
  border: 1px solid #eaeaea;
  background-color: #fff;

  &__header {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3.6rem;

    & > * {
      padding-bottom: 1.2rem;
      border-bottom: 2px solid $bg-primary;
    }
  }

  &__ref {
    padding: 1.2rem 0;
    border-bottom: 2px solid $bg-primary;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3.6rem;
  }

  &__text {
    font-size: 1.5rem;
    font-style: italic;
    color: $bg-primary;
  }

  &__line {
    height: 2px;
    background-color: $bg-primary;
  }
}

.invoice-footer {
  max-width: 90rem;
  margin: auto;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.8rem;

  margin-top: 3.6rem;
}
