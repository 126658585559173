.info-block {
  padding: 1.2rem;
  border-radius: 1.6rem;
  background-color: #fff;

  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  &__info {
    display: flex;
    align-items: center;
    gap: 2.4rem;

    padding: 1.2rem 1.8rem;
    border-radius: 1.6rem;
    cursor: pointer;

    &:nth-child(odd) {
      background-color: #f1f1f1;
    }
  }

  &__info-key {
    flex: 0 0 10rem;
    font-weight: 600;
  }

  &__info-main {
    flex: 1;
  }
}
