.auth {
  width: 100vw;
  height: 100vh;

  display: flex;

  &--1 {
    height: auto;
  }

  &__left {
    flex: 0 0 60rem;

    background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
      url(./assets/img/dmx-auth-bg.jpeg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 7.2rem;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    position: relative;

    @media (max-width: 1250px) {
      flex: 0 0 35%;
    }

    @media (max-width: 850px) {
      display: none;
    }

    & img {
      position: absolute;
      top: 0;
      left: 0;

      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__right {
    flex: 1;

    padding: 4.8rem 7.2rem;
    background-color: var(--bg-color);

    @media (max-width: 550px) {
      padding: 4.8rem 2.4rem;
    }
  }

  &__main {
    max-width: 50rem;
    margin: auto;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 0 2.4rem;

    &--1 {
      justify-content: initial;
    }

    @media (max-height: 750px) {
      justify-content: flex-start;
    }
  }

  &__logo-box {
    text-align: center;
    margin-bottom: 4.8rem;
  }

  &__brand {
    font-size: 2.4rem;
    color: #000;
    font-weight: 600;
  }

  &__logo {
    display: inline-block;
    height: 7.2rem;

    &--sm {
      height: 4.8rem;
    }
  }

  &__btns {
    background-color: #eaeaea;
    padding: 0.8rem;
    border-radius: 1.2rem;

    display: flex;
    gap: 1.2rem;

    margin-bottom: 3.6rem;
  }

  &__btn {
    flex: 1;

    height: 5.2rem;
    font-family: inherit;
    border: none;
    background-color: transparent;
    cursor: pointer;
    border-radius: 0.6rem;
    color: var(--text-color);

    /*For link support*/
    @include btn-link-support;

    &--active {
      background-color: $bg-primary;
      color: $color-white;
    }
  }

  &__header {
    margin-bottom: 3.6rem;
  }

  &__header2 {
    display: flex;
    justify-content: space-between;
    gap: 2.4rem;

    margin-bottom: 2.4rem;
  }

  &__header2-right {
    flex: 0 0 6rem;

    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 0.8rem;
  }

  &__header2-step {
    // flex: 0 0 6rem;
    padding-top: 0.6rem;
    color: $color-info;
  }

  &__header2-step-navigate {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    & button {
      display: flex;
      align-items: center;
      justify-content: center;

      background-color: #eaeaea;
      border: none;
      cursor: pointer;
      border-radius: 3px;
      padding: 4px;

      & ion-icon {
        color: var(--text-color);
      }
    }
  }

  &__heading {
    font-size: 2.4rem;
    font-weight: 600;
    color: #000;

    &:not(:last-child) {
      margin-bottom: 0.2rem;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    margin-bottom: 2.4rem;
  }

  &__link {
    color: $color-info;
    text-decoration: none;
    font-weight: 500;
    cursor: pointer;
  }

  &__img {
    display: inline-block;
    width: 9.6rem;
    height: 9.6rem;
    border-radius: 50%;
    object-fit: cover;
  }

  &__footer {
    text-align: center;
  }

  &__profiles {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;

    margin-bottom: 1.2rem;
  }

  &__profile {
    padding: 1.6rem;
    border-radius: 1.2rem;
    background-color: #eaeaea;
    cursor: pointer;

    &--active {
      border: 1px solid $color-info;
    }
  }

  &__profile-img {
    margin-bottom: 1.2rem;
  }

  &__profile-name {
    font-size: 1.8rem;
    color: #000;
    margin-bottom: 0.2rem;
  }
}
