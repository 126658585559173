.search-form {
  width: 30rem;
  height: 4.8rem;
  border-radius: 0.8rem;
  background-color: $bg-primary-faint;
  padding: 0.8rem;
  padding-left: 1.6rem;

  display: flex;
  align-items: center;
  gap: 1.6rem;

  & ion-icon {
    display: block;
    width: 2.4rem;
    height: 2.4rem;
  }

  & input {
    background-color: transparent;
    border: none;
    flex: 1;
    color: $color-white;

    &::placeholder {
      color: $color-white;
    }
  }

  & button {
    align-self: stretch;
    border: none;
    background-color: $color-white;
    border-radius: 6px;
    padding: 0 1.6rem;
    font-weight: 500;
    color: $bg-primary;
  }
}

.form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2.4rem;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 6px;

  position: relative; // can't hurt

  & label span {
    font-size: 1.8rem;
    color: $color-danger;
    line-height: 1;
  }

  &--md {
    gap: 1.6rem;
  }
}

.form-group-horizontal {
  display: flex;
  align-items: center;
  gap: 2.4rem;

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: stretch;
    gap: 1.2rem;
  }

  & label {
    flex: 0 0 15rem;
    text-align: right;

    @media (max-width: 500px) {
      flex: initial;
      text-align: left;
    }
  }

  &__main {
    flex: 1;

    display: flex;
    flex-direction: column;
    gap: 1.8rem;

    position: relative;
  }
}

.input-box {
  height: 4.8rem;
  border-radius: 0.8rem;
  padding: 0 1.6rem;
  border: 1px solid #d7d7d7;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    background-color: #f1f1f1;
  }

  &--disabled {
    background-color: #eaeaea !important;
  }

  & ion-icon {
    display: block;
    width: 1.8rem;
    height: 1.8rem;
  }
}

.input-group {
  display: flex;
  height: 4.8rem;
  border-radius: 0.8rem;
  overflow: hidden;

  & button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;
    font-family: inherit;
    border: 1px solid #d7d7d7;
    padding: 0 1.6rem;
    cursor: pointer;
    background-color: #eaeaea;
    color: #555;
    min-width: 4.8rem;

    &:first-child {
      border-top-left-radius: 0.8rem;
      border-bottom-left-radius: 0.8rem;
      border-right: none;
    }

    &:last-child {
      border-top-right-radius: 0.8rem;
      border-bottom-right-radius: 0.8rem;
      border-left: none;
    }

    & ion-icon {
      display: block;
      width: 1.8rem;
      height: 1.8rem;
    }
  }

  & input {
    font-family: inherit;
    padding: 0 1.6rem;
    min-width: 0;
    flex: 1;
    border: 1px solid #d7d7d7;
    outline: none;
    background-color: transparent;

    &:first-child {
      border-top-left-radius: 0.8rem;
      border-bottom-left-radius: 0.8rem;
    }

    &:last-child {
      border-top-right-radius: 0.8rem;
      border-bottom-right-radius: 0.8rem;
    }

    &:focus {
      border: 2px solid #777;
    }
  }

  &--disabled input:first-child {
    border-right: none;
  }

  &__clear {
    padding: 0 1.2rem;
    flex-shrink: 0;
    cursor: pointer;
    border-top: 1px solid #d7d7d7;
    border-bottom: 1px solid #d7d7d7;
    border-right: 1px solid #d7d7d7;

    display: flex;
    align-items: center;
    justify-content: center;

    & ion-icon {
      display: block;
      width: 1.8rem;
      height: 1.8rem;
      color: $text-color;
    }
  }

  &--disabled {
    background-color: #eaeaea;
  }
}

.form-input-group {
  width: 100%;
  height: 4.8rem;
  padding: 0 1.6rem;
  border: 1px solid #d7d7d7;
  border-radius: 0.8rem;
  overflow: hidden;

  display: flex;
  align-items: center;
  gap: 1.2rem;

  &--1 {
    padding-left: 0;
  }

  &--2 {
    padding-right: 0;
  }

  & input {
    flex: 1;
    min-width: 0;
    border: none;
    background-color: transparent;
    outline: none;
    font-family: inherit;
    color: $text-color;
  }

  & button {
    align-self: stretch;

    background-color: transparent;
    border: none;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1.2rem;

    & ion-icon {
      display: block;
      width: 1.8rem;
      height: 1.8rem;
      color: $text-color;
    }
  }

  &--1,
  &--2 {
    & button {
      background-color: #eaeaea;
      padding: 0 1.6rem;
    }
  }
}

.form-input,
.form-select {
  display: block;
  width: 100%;
  height: 4.8rem;
  padding: 0 1.6rem;
  border: 1px solid #d7d7d7;
  background-color: transparent;
  border-radius: 0.8rem;
  outline: none;
  font-family: inherit;
  color: $text-color;

  &:focus {
    border: 2px solid #777;
  }

  &:disabled {
    background-color: #eaeaea;
  }
}

textarea.form-input {
  height: auto;
  padding: 1.6rem;
}

.form-select {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='grey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: 50%;
}

.accept {
  display: flex;
  align-items: center;
  gap: 1.2rem;

  font-weight: 500;

  &__checkbox {
    flex-shrink: 0;
    appearance: none;
    cursor: pointer;
    display: block;
    width: 1.8rem;
    height: 1.8rem;
    background-color: #d7d7d7;
    border-radius: 3px;
    position: relative;

    &:checked {
      background-color: $color-info;
    }

    &::after {
      content: "\2713";
      display: block;
      color: #fff;
      font-weight: 700;
      font-size: 12px;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      display: none;
    }

    &:checked::after {
      display: block;
    }
  }
}

.label-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > span {
    display: flex;
    align-items: center;
    gap: 6px;

    text-transform: uppercase;
    color: $color-info;
    cursor: pointer;

    & ion-icon {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
    }
  }
}

.file-drop {
  border: 1px dashed $color-info;
  border-radius: 0.8rem;
  height: 20rem;
  cursor: pointer;
  padding: 0 2.4rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;

  & ion-icon {
    display: block;
    width: 4.8rem;
    height: 4.8rem;
    margin-bottom: 1.8rem;
    color: $color-info;
  }

  &__info-1 {
    color: #262626;
    margin-bottom: 6px;

    & span {
      cursor: pointer;
      color: $color-info;
    }
  }

  &__info-2 {
    font-size: 1.2rem;
    color: #9a9a9a;
  }
}

.filter {
  display: flex;
  gap: 1.2rem;

  height: 6.4rem;
  padding: 0.8rem;
  border-radius: 0.8rem;
  background-color: #fff;

  & select {
    min-width: 15rem;
  }

  &__control {
    flex-shrink: 0;
  }
}

.form-steps {
  display: flex;
  align-items: center;
  gap: 1.2rem;

  &__divider {
    flex: 1;

    height: 1px;
    background-color: #ccc;

    &.active {
      background-color: $bg-primary;
    }
  }

  &__step {
    flex-shrink: 0;

    display: flex;
    align-items: center;
    gap: 1.2rem;

    cursor: pointer;
  }

  &__step-number {
    display: flex;
    align-items: center;
    justify-content: center;

    border: 1px solid #ccc;
    border-radius: 50%;
    width: 3.6rem;
    height: 3.6rem;

    &.active {
      border: 1px solid $bg-primary;
      background-color: $bg-primary;
      color: #fff;
    }

    & ion-icon {
      display: block;
      width: 1.8rem;
      height: 1.8rem;
    }
  }
}

.form-info {
  padding: 1.6rem;
  border: 1px solid #d7d7d7;
  background-color: #eaeaea;
  border-radius: 0.8rem;
}
