.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.2rem;

  & button {
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 4px;
    border: 1px solid $color-info;
    color: $color-info;
    font-size: 1.8rem;
    line-height: 0;
    cursor: pointer;
    background-color: transparent;
    transition: all 0.2s;
    font-family: inherit;

    display: flex;
    align-items: center;
    justify-content: center;

    &.active,
    &:hover {
      background-color: $color-info;
      color: #fff;
    }

    // & ion-icon {
    //   display: block;
    //   width: 1.8rem;
    //   height: 1.8rem;
    // }
  }
}
