.header {
  height: 8rem;
  z-index: 999999;
  background-color: $bg-primary;
  color: $color-white;

  position: sticky; // fixed
  top: 0;
  left: 0;
  width: 100%;

  & .search-form {
    @media (max-width: 650px) {
      display: none;
    }
  }

  &__container {
    height: 100%;

    display: flex;
    align-items: center;
    gap: 4.8rem;

    background-color: $bg-primary;
    z-index: 999999;
  }

  &__brand {
    // font-size: 2.4rem;
    // font-weight: 700;

    display: flex;
    align-items: center;
    gap: 1.8rem;

    @media (max-width: 1200px) {
      margin-right: auto;
    }
  }

  &__logo {
    height: 3.2rem;
  }

  &__menu {
    margin-right: auto;

    display: flex;
    align-items: center;
    gap: 2.4rem;
  }

  &__menu-link {
    &:link,
    &:visited {
      color: $color-white;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    gap: 2.4rem;
  }

  &__action {
    position: relative;

    & ion-icon {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
      color: $color-white;
    }
  }

  &__user {
    position: relative;
  }

  &__user-main {
    display: flex;
    align-items: center;
    gap: 8px;

    cursor: pointer;

    & img {
      display: block;
      width: 3.6rem;
      height: 3.6rem;
      border-radius: 50%;
    }
  }

  &__user-block {
    color: #555;

    position: absolute;
    bottom: 0;
    right: 0;
    width: 40rem;
    max-width: calc(100vh - 4.8rem);
    transform: translateY(100%);
    z-index: 99999;

    display: none;

    &:hover {
      display: block;
    }
  }

  &__user-main:hover + &__user-block {
    display: block;
  }

  &__user-block-top {
    height: 3.6rem;
  }

  &__user-block-main {
    background-color: #fff;
    padding: 1.8rem;
    border-radius: 2.4rem;

    box-shadow: 0 8px 64px rgba($color: #000000, $alpha: 0.1);
  }

  &__user-email {
    color: #555;
    text-align: center;
    font-weight: 600;
    margin-bottom: 2.4rem;
  }

  &__user-user {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;

    margin-bottom: 3.6rem;
  }

  &__user-img {
    display: block;
    width: 9.6rem;
    height: 9.6rem;
    border-radius: 50%;
  }

  &__user-name {
    font-size: 2.4rem;
    color: #333;
    font-weight: 600;
  }

  &__user-role {
  }

  &__user-menu {
    background-color: #f7f7f7;
    border-radius: 2.4rem;
    overflow: hidden;
  }

  &__user-menu-item {
    &:not(:last-child) {
      border-bottom: 2px solid #fff;
    }
  }

  &__user-menu-link {
    &:link,
    &:visited {
      display: flex;
      align-items: center;
      gap: 1.2rem;

      padding: 1.6rem 2.8rem;
      color: #555;

      transition: all 0.3s;
    }

    &.active,
    &:hover,
    &:active {
      background-color: #f1f1f1;
    }
  }
}

.navbar {
  margin-right: auto;

  list-style: none;

  display: flex;
  align-items: stretch;
  gap: 1.2rem;

  @media (max-width: 1200px) {
    position: fixed;
    top: 8rem;
    left: 0;
    width: 100%;

    max-width: 30rem;
    background-color: #fff;

    margin: 2.4rem;
    border-radius: 1.2rem;
    box-shadow: 0 8px 64px rgba(0, 0, 0, 0.1);

    display: flex;
    flex-direction: column;
    gap: 0;

    transition: all 0.4s;

    transform: translateY(-120%);
    z-index: -1;
  }

  &--open & {
    @media (max-width: 1200px) {
      transform: translateY(0);
    }
  }

  &__item {
    display: flex;
    align-items: center;

    @media (max-width: 1200px) {
      width: 100%;

      &:not(:last-child) {
        border-bottom: 1px solid #f1f1f1;
      }
    }
  }

  &__link {
    &:link,
    &:visited {
      color: $color-white;
      padding: 1rem 1.8rem;
      border-radius: 6px;
      z-index: 99;
      text-decoration: none;

      display: flex;
      align-items: center;
      gap: 6px;
    }

    &--active,
    &:hover,
    &:active {
      background-color: rgba(255, 255, 255, 0.1);
    }

    @media (max-width: 1200px) {
      &:link,
      &:visited {
        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
        color: $text-color;
        // background-color: var(--header-bg);
        border-left: 3px solid transparent;
        border-radius: 0;

        width: 100%;

        justify-content: space-between;
      }

      &--active:link,
      &--active:visited,
      &:hover,
      &:active {
        // background-color: var(--dashboard-header-submenu-hover);
        color: $bg-primary;
        // border-left: 3px solid $color-primary-light;
      }
    }
  }

  &__item-has-submenu {
    position: relative;

    @media (max-width: 1200px) {
      flex-direction: column;
    }
  }

  &__sub-menu {
    position: absolute;
    right: 0;
    top: 0;
    padding-top: 7.2rem;

    width: 20rem;

    display: none;

    @media (max-width: 1200px) {
      position: relative;

      padding-top: 0;
      width: 100%;
      border-top: 1px solid #f1f1f1;
    }
  }

  &__item-has-submenu:hover &__sub-menu {
    display: block;
  }

  &__sub-menu-menu {
    border-radius: 0.8rem;
    overflow: hidden;
    box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.15);

    @media (max-width: 1200px) {
      border-radius: 0;
      box-shadow: none;
    }
  }

  &__sub-menu-item {
    &:not(:last-child) {
      border-bottom: 1px solid #f7f7f7;
    }
  }

  &__sub-menu-link {
    &:link,
    &:visited {
      display: flex;
      align-items: center;
      gap: 8px;

      padding: 1.2rem;
      color: $text-color;
      background-color: #fff;
      border-left: 3px solid transparent;
      text-decoration: none;

      @media (max-width: 1200px) {
        border-left: none;
      }
    }

    &--active:link,
    &--active:visited,
    &:hover,
    &:active {
      background-color: $bg-primary-fainter;
      color: $bg-primary;
      border-left: 3px solid $bg-primary;

      @media (max-width: 1200px) {
        border-left: none;
      }
    }

    & ion-icon {
      display: block;
      width: 1.6rem;
      height: 1.6rem;
    }

    @media (max-width: 1200px) {
      &:link,
      &:visited {
        background: transparent;
        text-align: left;

        padding-top: 1.6rem;
        padding-bottom: 1.6rem;
        padding-left: 4.8rem;
      }
    }
  }

  @media (max-width: 1200px) {
    &__sub-menu-item:not(:last-child) &__sub-menu-link {
      border-bottom: none;
    }
  }

  &__wallet-btns {
    margin-left: 4.8rem;

    display: flex;
    align-items: center;
    gap: 1.2rem;

    @media (max-width: 1200px) {
      margin-left: 0;
      padding: 12px;
      // background-color: var(--dashboard-header-submenu-hover);

      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 12px;
    }
  }

  &__btn {
    background-color: transparent;
    border: none;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;

    display: none;

    @media (max-width: 1200px) {
      display: flex;
    }

    & ion-icon {
      display: block;
      color: $color-white;
      width: 3.2rem;
      height: 3.2rem;

      &:first-child {
        display: block;
      }

      &:last-child {
        display: none;
      }
    }
  }

  &--open &__btn ion-icon {
    &:first-child {
      display: none;
    }

    &:last-child {
      display: block;
    }
  }
}
