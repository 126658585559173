.badge {
  display: inline-block;
  padding: 0.6rem 1.2rem;
  border-radius: 10rem;
  font-size: 1.25rem;

  &--danger {
    background-color: rgba($color: $color-danger, $alpha: 0.2);
    color: $color-danger;
  }

  &--warning {
    background-color: rgba($color: $color-warning, $alpha: 0.2);
    color: $color-warning;
  }

  &--info {
    background-color: rgba($color: $color-info, $alpha: 0.2);
    color: $color-info;
  }

  &--success {
    background-color: rgba($color: $color-success, $alpha: 0.2);
    color: $color-success;
  }
}
