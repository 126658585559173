.dashboard-header {
  padding-top: 2.4rem;
  padding-bottom: 8.4rem;
  background-color: $bg-primary;
  color: $color-white;

  @media (max-width: 1300px) {
    background-color: transparent;
    color: $text-color;
  }

  &--diff {
    background-color: transparent;
    color: $text-color;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.6rem;

    @media (max-width: 1300px) {
      flex-direction: column;
    }
  }

  &--diff &__container {
    flex-direction: column;
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: 4px;

    @media (max-width: 1300px) {
      text-align: center;
    }
  }

  &--diff &__main {
    text-align: center;
  }

  &__heading {
    font-size: 3.2rem;
    font-weight: 600;
  }

  &__text {
    opacity: 0.5;
  }
}

.dsection {
  display: flex;
  flex-direction: column;
  gap: 4.8rem;
}

.dcard-main-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.6rem;

  margin-top: -8.4rem;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
  }
}

.dcard {
  padding: 2.4rem 3.2rem;
  background-color: #fff;
  border-radius: 1.2rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.2rem;

  &--info {
    border-bottom: 4px solid #228be6;
  }

  &--danger {
    border-bottom: 4px solid #fa5252;
  }

  &--success {
    border-bottom: 4px solid #40c057;
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__value {
    color: #333;
    font-weight: 600;
    font-size: 3rem;
  }

  &__icon-block {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 4.8rem;
    height: 4.8rem;
    border-radius: 1.2rem;

    & ion-icon {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
    }
  }

  &--info &__icon-block {
    background-color: rgba(#228be6, 0.1);

    & ion-icon {
      color: #228be6;
    }
  }

  &--success &__icon-block {
    background-color: rgba(#40c057, 0.1);

    & ion-icon {
      color: #40c057;
    }
  }

  &--danger &__icon-block {
    background-color: rgba(#fa5252, 0.1);

    & ion-icon {
      color: #fa5252;
    }
  }
}

.info-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.6rem;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 850px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 450px) {
    grid-template-columns: 1fr;
  }
}

.dheader {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 1.8rem;
}

.dheading {
  font-size: 2rem;
  color: #000;
}
