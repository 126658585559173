.loaderVBars-container {
  height: 4.2rem;
}

.loaderVBars {
  display: inline-block;
  position: relative;
  width: 8rem;
  height: 8rem;

  &--sm {
    transform: scale(0.5) translateY(-50%);
    height: 4rem;
    width: 4rem;
  }

  & div {
    display: inline-block;
    position: absolute;
    left: 0.8rem;
    width: 1.6rem;
    background: $bg-primary;
    animation: loaderVBars 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }

  & div:nth-child(1) {
    left: 0.8rem;
    animation-delay: -0.24s;
  }

  & div:nth-child(2) {
    left: 3.2rem;
    animation-delay: -0.12s;
  }

  & div:nth-child(3) {
    left: 5.6rem;
    animation-delay: 0;
  }
}

.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999999;
  background-color: rgba($color: #262626, $alpha: 0.4);

  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  transform: scale(0.5);

  & div {
    transform-origin: 40px 40px;
    animation: loaderSpinner 1.2s linear infinite;
  }

  & div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #345fcf;
  }

  & div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }

  & div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }

  & div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }

  & div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }

  & div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }

  & div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }

  & div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }

  & div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }

  & div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }

  & div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }

  & div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }

  & div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
}
