*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

body {
  box-sizing: border-box;
  background-color: #f7f7f7;
}

ul {
  list-style: none;
}

a {
  &:link,
  &:visited {
    text-decoration: none;
  }
}

input {
  min-width: 0;
  font-family: inherit;
  outline: none;
}

button {
  font-family: inherit;
  cursor: pointer;
  border: none;
}
