.waybill-header {
  max-width: 90rem;
  margin: auto;

  text-align: center;
  margin-bottom: 2.4rem;
}

.waybill {
  max-width: 90rem;
  margin: auto;

  padding: 2.4rem;
  border: 1px solid #eaeaea;
  background-color: #fff;

  &__block {
    padding: 2.4rem 0;

    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2.4rem;
    row-gap: 2.4rem;

    border-bottom: 1px solid #eaeaea;

    @media (max-width: 650px) {
      grid-template-columns: 1fr;
    }

    &:first-child {
      padding-top: 0;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }

  &__rider {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    box-shadow: 0 8px 64px rgba(0, 0, 0, 0.1);
    padding: 0.8rem;
    padding-right: 1.6rem;
    border-top-left-radius: 10rem;
    border-bottom-left-radius: 10rem;
    border-top-right-radius: 1.2rem;
    border-bottom-right-radius: 1.2rem;

    margin-top: 1.2rem;
  }

  &__rider-img {
    display: block;
    width: 6.2rem;
    height: 6.2rem;
    border-radius: 50%;
  }

  &__rider-infos {
    flex: 1;

    display: flex;
    flex-direction: column;
    gap: 3px;
  }

  &__rider-name {
    font-weight: 700;
  }

  &__rider-info {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    font-size: 1.3rem;

    & ion-icon {
      display: block;
    }
  }

  &__heading {
    font-size: 1.6rem;
    color: #555;
    font-weight: 600;
  }

  &__detail {
    display: flex;
    align-items: center;
    gap: 1.2rem;

    & > *:first-child {
      flex: 0 0 15rem;
    }
  }

  &__cargo-text {
    font-style: italic;
  }

  &__activity-log {
    padding-top: 2.4rem;

    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }

  &__alert {
    display: flex;
    align-items: center;
    gap: 1.6rem;

    height: 4.8rem;
    border: 1px solid rgba(101, 200, 237, 0.9);
    background-color: rgba(215, 241, 250, 0.9);
  }

  &__alert-icon {
    align-self: stretch;
    flex: 0 0 4.8rem;

    background-color: rgba(101, 200, 237, 0.9);

    display: flex;
    align-items: center;
    justify-content: center;

    & ion-icon {
      display: block;
      width: 2.4rem;
      height: 2.4rem;
      color: #fff;
    }
  }
}

.waybill-footer {
  max-width: 90rem;
  margin: auto;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.8rem;

  margin-top: 3.6rem;
}
